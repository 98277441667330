<!-- Start Main Container Area -->
<div class="main-container">
    <aside id="placid-aside" class="placid-aside bg-0f1d22">
        <div class="logo">
            <a routerLink="/">
                <img src="assets/img/logo.png" alt="image" style="max-height: 150px;">
            </a>
        </div>
        <div class="d-table">
            <div class="d-table-cell">
                <ul class="navbar-nav">
                    <li class="nav-item"><a class="nav-link active" href="#home">Home</a></li>
                    <li class="nav-item"><a class="nav-link" href="#about">About</a></li>
                    <li class="nav-item"><a class="nav-link" href="#services">Services</a></li>
                    <li class="nav-item"><a class="nav-link" href="#portfolio">Portfolio</a></li>
                    <li class="nav-item"><a class="nav-link" href="#contact">Contact</a></li>
                </ul>
            </div>
        </div>
        <div class="aside-footer">
            <a href="#contact" class="default-btn">Español</a>
            <ul class="social">
                <li><a href="#" target="_blank"><i class="ri-facebook-box-line"></i></a></li>
                <li><a href="#" target="_blank"><i class="ri-twitter-line"></i></a></li>
                <li><a href="#" target="_blank"><i class="ri-linkedin-box-line"></i></a></li>
                <li><a href="#" target="_blank"><i class="ri-dribbble-fill"></i></a></li>
                <li><a href="#" target="_blank"><i class="ri-instagram-line"></i></a></li>
            </ul>
        </div>
        <div class="line"></div>
    </aside>

    <div id="main-content" class="main-content">
        <div class="responsive-burger-menu">
            <span class="top-bar"></span>
            <span class="middle-bar"></span>
            <span class="bottom-bar"></span>
        </div>

        <div class="main-banner banner-bg1 jarallax" data-jarallax='{"speed": 0.3}'>
            <!--div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="main-banner-content">
                            <span class="sub-title">Hello I'am</span>
                            <h1>Alexander Ludwig</h1>
                            <h6>I’am a <span>Digital Marketer</span></h6>
                        </div>
                    </div>
                </div>
            </div-->

            <div class="scroll-btn">
                <a href="#about">
                    <span class="mouse">
                        <span></span>
                    </span>
                </a>
            </div>
        </div>

        <section id="about" class="about-area ptb-120">
            <div class="container">
                <div class="section-title">
                    <span class="sub-title">About Us</span>
                    <h2>Tu socio de confianza para Traducciones</h2>
                </div>

                <div class="row align-items-center">
                    <div class="col-lg-8 col-md-12">
                        <div class="about-content">
                            <p>Nos enorgullece ser tu socio de confianza para servicios de traducción libre y jurada de
                                la más alta calidad. Con más de 15 años de experiencia en el campo, hemos consolidado
                                nuestra reputación como líderes en la industria, ofreciendo soluciones lingüísticas
                                precisas y confiables.</p>

                            <div class="tab about-list-tab">
                                <ul class="tabs">
                                    <li>Experiencia</li>
                                    <li>Profesionalismo</li>
                                    <li>Servicios</li>
                                    <li>Compromiso</li>
                                </ul>

                                <div class="tab-content">
                                    <div class="tabs-item">
                                        <div class="skills-area">
                                            <div class="row">
                                                <p>Con más de 15 años de experiencia, nuestro equipo de traductores
                                                    expertos garantiza resultados de la más alta calidad.</p>

                                            </div>
                                        </div>
                                    </div>

                                    <div class="tabs-item">
                                        <div class="awards-area">
                                            <div class="row">
                                                <p>Nos comprometemos a mantener los más altos estándares éticos y
                                                    profesionales en cada proyecto.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="tabs-item">
                                        <div class="experience-area">
                                            <div class="row">
                                                <div class="col-lg-12 col-md-12 col-sm-12" style="margin-bottom: 40px;">
                                                    <p>Ofrecemos una amplia gama de servicios de traducción jurada para
                                                        adaptarnos a tus necesidades específicas.</p>
                                                    <br />
                                                    <p>Cada cliente es único. Nos tomamos el tiempo para entender tus
                                                        necesidades y ofrecer soluciones personalizadas.</p>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div class="tabs-item">
                                        <div class="education-area">
                                            <div class="row">
                                                <p>Entendemos la importancia de la precisión en la traducción jurada y
                                                    nos esforzamos por ofrecer traducciones que reflejen fielmente el
                                                    significado original.</p>
                                                <p>&nbsp;</p>
                                                <p> La calidad es nuestra prioridad. Nos enorgullece proporcionar
                                                    traducciones juradas precisas y certificadas que cumplen con los más
                                                    altos estándares legales y lingüísticos. Cada proyecto es abordado
                                                    con la atención meticulosa que merece, asegurando que cada detalle
                                                    sea cuidadosamente considerado.</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="col-lg-4 col-md-12">
                        <div class="about-image">
                            <img src="assets/img/about-img1.jpg" alt="image">
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <div class="footer-bottom-area">
                            <div class="line"></div>
                            <p>Descubre por qué somos la elección preferida para aquellos que buscan servicios de
                                traducción jurada de calidad. Ya sea que necesites traducciones para asuntos
                                legales,
                                documentos académicos o negocios internacionales, estamos aquí para cumplir tus
                                expectativas y superarlas.
                            </p>

                            <p>¡Esperamos tener el honor de trabajar contigo!</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section id="services" class="services-area pt-120 pb-90 bg-0f1d22">
            <div class="container">
                <div class="section-title">
                    <span class="sub-title">Servicios</span>
                    <h2>Servicios de Traducción de Calidad a tu Alcance</h2>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-md-6">
                        <div class="single-services-box">
                            <div class="icon">
                                <i class="flaticon-result"></i>
                            </div>
                            <h3>Business Research</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                                ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                            <a routerLink="/services-details" class="read-more-btn">Read More<i
                                    class="ri-arrow-right-s-line"></i></a>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-6 col-md-6">
                        <div class="single-services-box">
                            <div class="icon">
                                <i class="flaticon-email"></i>
                            </div>
                            <h3>Email Marketing</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                                ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                            <a routerLink="/services-details" class="read-more-btn">Read More<i
                                    class="ri-arrow-right-s-line"></i></a>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-6 col-md-6">
                        <div class="single-services-box">
                            <div class="icon">
                                <i class="flaticon-content"></i>
                            </div>
                            <h3>Informative Content</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                                ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                            <a routerLink="/services-details" class="read-more-btn">Read More<i
                                    class="ri-arrow-right-s-line"></i></a>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-6 col-md-6">
                        <div class="single-services-box">
                            <div class="icon">
                                <i class="flaticon-education"></i>
                            </div>
                            <h3>Innovative Strategies</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                                ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                            <a routerLink="/services-details" class="read-more-btn">Read More<i
                                    class="ri-arrow-right-s-line"></i></a>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-6 col-md-6">
                        <div class="single-services-box">
                            <div class="icon">
                                <i class="flaticon-analysis"></i>
                            </div>
                            <h3>Brand Identity</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                                ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                            <a routerLink="/services-details" class="read-more-btn">Read More<i
                                    class="ri-arrow-right-s-line"></i></a>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-6 col-md-6">
                        <div class="single-services-box">
                            <div class="icon">
                                <i class="flaticon-team"></i>
                            </div>
                            <h3>Reputation Management</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                                ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                            <a routerLink="/services-details" class="read-more-btn">Read More<i
                                    class="ri-arrow-right-s-line"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section id="portfolio" class="works-area pt-120 pb-90">
            <div class="container">
                <div class="section-title">
                    <span class="sub-title">Works</span>
                    <h2>Some of My Creative Projects</h2>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-md-4 col-sm-12">
                        <div class="single-works-box">
                            <div class="works-image">
                                <img src="assets/img/works/img1.jpg" alt="image">
                                <div class="overlay"></div>
                                <a routerLink="/works-details" class="link-btn"></a>
                            </div>

                            <div class="works-content">
                                <h3><a routerLink="/works-details">Festival Banner</a></h3>
                                <ul>
                                    <li><a href="#">Web</a></li>
                                    <li><a href="#">Banner</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-8 col-md-8 col-sm-12">
                        <div class="single-works-box">
                            <div class="works-image">
                                <img src="assets/img/works/img2.jpg" alt="image">
                                <div class="overlay"></div>
                                <a routerLink="/works-details" class="link-btn"></a>
                            </div>

                            <div class="works-content">
                                <h3><a routerLink="/works-details">Book Cover Presentation</a></h3>
                                <ul>
                                    <li><a href="#">Design</a></li>
                                    <li><a href="#">Book</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="single-works-box">
                            <div class="works-image">
                                <img src="assets/img/works/img3.jpg" alt="image">
                                <div class="overlay"></div>
                                <a routerLink="/works-details" class="link-btn"></a>
                            </div>

                            <div class="works-content">
                                <h3><a routerLink="/works-details">Poster Vector</a></h3>
                                <ul>
                                    <li><a href="#">HTML</a></li>
                                    <li><a href="#">CSS</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="single-works-box">
                            <div class="works-image">
                                <img src="assets/img/works/img4.jpg" alt="image">
                                <div class="overlay"></div>
                                <a routerLink="/works-details" class="link-btn"></a>
                            </div>

                            <div class="works-content">
                                <h3><a routerLink="/works-details">Content Writing</a></h3>
                                <ul>
                                    <li><a href="#">Web</a></li>
                                    <li><a href="#">Text</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                        <div class="single-works-box">
                            <div class="works-image">
                                <img src="assets/img/works/img5.jpg" alt="image">
                                <div class="overlay"></div>
                                <a routerLink="/works-details" class="link-btn"></a>
                            </div>

                            <div class="works-content">
                                <h3><a routerLink="/works-details">Digital Marketing</a></h3>
                                <ul>
                                    <li><a href="#">Web</a></li>
                                    <li><a href="#">HTML</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="feedback-area">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-5 col-md-12">
                        <div class="feedback-content jarallax" data-jarallax='{"speed": 0.3}'>
                            <div class="d-table">
                                <div class="d-table-cell">
                                    <div class="section-title">
                                        <span class="sub-title">Testimonials</span>
                                        <h2>What my clients say about me</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-7 col-md-12">
                        <div class="feedback-slides-content bg-0f1d22">
                            <div class="feedback-slides owl-carousel owl-theme">
                                <div class="single-feedback-item">
                                    <i class="ri-double-quotes-r"></i>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do tempor incididunt
                                        ut labore et dolore magna aliqua. eiusmod Quis ipsum suspendisse ultrices
                                        gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>

                                    <div class="client-info">
                                        <h3>Jason Statham</h3>
                                        <span>Web Developer</span>
                                    </div>
                                </div>

                                <div class="single-feedback-item">
                                    <i class="ri-double-quotes-r"></i>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do tempor incididunt
                                        ut labore et dolore magna aliqua. eiusmod Quis ipsum suspendisse ultrices
                                        gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>

                                    <div class="client-info">
                                        <h3>Sarah Taylor</h3>
                                        <span>Web Designer</span>
                                    </div>
                                </div>

                                <div class="single-feedback-item">
                                    <i class="ri-double-quotes-r"></i>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do tempor incididunt
                                        ut labore et dolore magna aliqua. eiusmod Quis ipsum suspendisse ultrices
                                        gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>

                                    <div class="client-info">
                                        <h3>David Warner</h3>
                                        <span>PHP Developer</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <div class="my-clients-area ptb-120">
            <div class="container">
                <div class="section-title">
                    <span class="sub-title">Clients</span>
                    <h2>My Honoranle Clients</h2>
                </div>

                <div class="row">
                    <div class="col-lg-3 col-md-4 col-sm-6 col-6">
                        <div class="single-clients">
                            <a href="#">
                                <img src="assets/img/clients/img1.png" alt="image">
                            </a>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-4 col-sm-6 col-6">
                        <div class="single-clients">
                            <a href="#">
                                <img src="assets/img/clients/img2.png" alt="image">
                            </a>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-4 col-sm-6 col-6">
                        <div class="single-clients">
                            <a href="#">
                                <img src="assets/img/clients/img3.png" alt="image">
                            </a>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-4 col-sm-6 col-6">
                        <div class="single-clients">
                            <a href="#">
                                <img src="assets/img/clients/img4.png" alt="image">
                            </a>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-4 col-sm-6 col-6">
                        <div class="single-clients">
                            <a href="#">
                                <img src="assets/img/clients/img5.png" alt="image">
                            </a>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-4 col-sm-6 col-6">
                        <div class="single-clients">
                            <a href="#">
                                <img src="assets/img/clients/img6.png" alt="image">
                            </a>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-4 col-sm-6 col-6">
                        <div class="single-clients">
                            <a href="#">
                                <img src="assets/img/clients/img7.png" alt="image">
                            </a>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-4 col-sm-6 col-6">
                        <div class="single-clients">
                            <a href="#">
                                <img src="assets/img/clients/img8.png" alt="image">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <section class="hire-me-area bg-0f1d22 ptb-120">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-7 col-md-12">
                        <div class="hire-me-content">
                            <span class="sub-title">Have You Any Project?</span>
                            <h2>I am Available for Freelance Hire</h2>
                        </div>
                    </div>

                    <div class="col-lg-5 col-md-12">
                        <div class="hire-me-btn">
                            <a href="#contact" class="default-btn">Hire Me</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section id="contact" class="contact-area">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-5 col-md-12">
                        <div class="contact-content jarallax" data-jarallax='{"speed": 0.3}'>
                            <div class="d-table">
                                <div class="d-table-cell">
                                    <div class="section-title">
                                        <span class="sub-title">Let's talk</span>
                                        <h2>Get in Touch With Me</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-7 col-md-12">
                        <div class="contact-form bg-0f1d22">
                            <form id="contactForm">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12">
                                        <div class="form-group mb-3">
                                            <input type="text" name="name" id="name" class="form-control"
                                                placeholder="Enter your name">
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12">
                                        <div class="form-group mb-3">
                                            <input type="email" name="email" id="email" class="form-control"
                                                placeholder="Enter your email">
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12">
                                        <div class="form-group mb-3">
                                            <textarea name="message" class="form-control" id="message" cols="30"
                                                rows="5" placeholder="Enter your message"></textarea>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12">
                                        <button type="submit" class="default-btn">Send Message</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <footer class="footer-area">
            <div class="container">
                <div class="footer-content">
                    <div class="logo">
                        <a routerLink="/">
                            <img src="assets/img/logo.png" alt="image">
                        </a>
                    </div>
                    <div class="footer-contact-info">
                        <ul>
                            <li><i class="ri-map-pin-line"></i> <a href="#" target="_blank">27 Division St, New York, NY
                                    10002, USA</a></li>
                            <li><i class="ri-phone-line"></i> <a href="tel:+321984754">(+321) 984 754</a></li>
                            <li><i class="ri-mail-line"></i> <a
                                    href="mailto:hello&#64;placid.com">hello&#64;placid.com</a></li>
                        </ul>
                    </div>
                    <ul class="social">
                        <li><a href="#" target="_blank">Facebook</a></li>
                        <li><a href="#" target="_blank">Twitter</a></li>
                        <li><a href="#" target="_blank">Linkedin</a></li>
                        <li><a href="#" target="_blank">Dribbble</a></li>
                        <li><a href="#" target="_blank">Instagram</a></li>
                    </ul>
                </div>
            </div>
            <div class="footer-bottom-area">
                <div class="container">
                    <p>© Translator es orgullosamente parte del grupo <a href="https://www.globalsiag.com/"
                            target="_blank">GLOBAL SIAG</a></p>
                </div>
                <div class="line"></div>
            </div>
        </footer>
    </div>
</div>
<!-- End Main Container Area -->